<template>
  <div class="rightLink">
    <div class="bottomDiv" style="padding: 0px 25px;height:750px">
      <h5 class="title"><i class="glyphicon glyphicon-user"></i> 角色列表</h5>
      <!-- <el-button
          type="primary"
          @click="(addRoleDiaLogFlag = true), (DiaLogFlagTitle = '角色添加')"
          size="small"
        >
          <i class="glyphicon glyphicon-plus"></i> 新增角色
        </el-button> -->
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 95 %;"
        stripe
        height="630px"
        size="medium"
        :header-row-style="{ color: '#333' }"
      >
        <el-table-column type="index" label="序号" width="80" />
        <el-table-column prop="roles_name" label="角色名称" />

        <el-table-column label="操作" min-width="184" fixed="right">
          <template #default="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              ><i class="glyphicon glyphicon-edit"></i> 编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              ><i class="glyphicon glyphicon-trash"></i> 清除权限</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div v-dialogdrag>
        <el-dialog
          :before-close="closeDialog"
          v-model="addRoleDiaLogFlag"
          :title="DiaLogFlagTitle"
          style="width: 50%;"
        >
          <el-form :model="form" style="width:60%; margin-left:17%">

            <el-form-item label="角色名称">
              <el-input
                disabled
                clearable
                v-model="form.roles_name"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item label="访问权限">
              <el-tree
                ref="tree"
                :data="routerData"
                :props="routerProps"
                @check="handleNodeClick"
                show-checkbox
                node-key="id"
                :default-checked-keys="form.pagepermission_ids"
              />
            </el-form-item>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="closeDialog">取消</el-button>
              <el-button type="primary" class="buttonPri" @click="submitDiaLog"
                >确认</el-button
              >
            </span>
          </template>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
  
  <style scoped>
.el-tree {
  height: 300px;
  width: 100%;
  overflow-y: scroll;
}
.iftext {
  width: 800px;
  height: 500px;
}
</style>
  
  <script>
import {
  roles_perm_g,
  // add_role,
  roles_perm_p,
  roles_perm_del,
  search_pagepermission,
  // search_pagepermission,
} from "@/request/api";
import { onMounted, reactive, toRefs, ref } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
export default {
  name: "sysConfigrole",
  setup() {
    const data = reactive({
      tableData: [], // 表格数据容器
      page: 1,
      current_page: 10,
      total: 0, // 条目总数
      DiaLogFlagTitle: "", // 弹框title
      addRoleDiaLogFlag: false, // 角色添加弹框显示
      loading: true,
      // 弹框表单数据容器
      form: {
        roles_name: "",
        perm_mod: "",
        // perm_id: "",
        // description: "",
        pagepermission_ids: [],
      },
      // 搜索
      searchData: {
        keyword: "",
      },
      // 暂存操作角色id
      role_id: "",
      // 暂存操作角色名称
      role_name: "",
      // 权限列表存储
      powerList: [],
      // 弹框 页面控制
      routerDiaLog: false,
      // 页面控制 数据容器 (页面列表容器)
      routerData: [],
      routerProps: {
        children: "children",
        label: "perm_info",
      },
      acitveRouter: [], // 选中的页面
    });
    // 请求数据获取角色列表
    const fetchData = () => {
      data.loading = true;
      const sdata = {
        keyword: data.searchData.keyword,
        page: data.page,
        current_page: data.current_page,
      };
      roles_perm_g(sdata)
        .then((res) => {
          if (res) {
            data.tableData = res.data;
            data.loading = false;
            data.total = res.total;
          } else {
            data.tableData = [];
            data.loading = false;
          }
        })
        .catch(() => {
          data.tableData = [];
          data.loading = false;
        });
    };
  
    // 表格内点击编辑按钮 编辑角色
    const handleEdit = (index, row) => {
      const rows = row.roles_permission;
      let rows1 = [];
      rows.forEach((option) => {
        rows1.push(option.id);
      });
      console.log(rows1,'rows1');
      data.DiaLogFlagTitle = "分配权限";
      data.form.roles_name = row.roles_name;
      data.form.perm_info = rows.perm_info;
      data.form.perm_list = rows.perm_list;
      data.form.perm_mod = rows.perm_mod;
      data.form.pagepermission_ids = rows1;
      data.acitveRouter = rows1;
      data.role_id = row.roles_id;
      data.addRoleDiaLogFlag = true;
      // data.role_id = rows.id;
      console.log(data.form, "data.form");
    };

    // 点击删除按钮 删除角色
    const handleDelete = (index, row) => {
      ElMessageBox.confirm("您确定要清清除角色权限吗？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {

      const rows = row.roles_permission;
      let rows1 = [];
      rows.forEach((option) => {
        rows1.push(option.id);
      });
      console.log(rows1,'rows1');
      data.DiaLogFlagTitle = "分配权限";
      data.form.roles_name = row.roles_name;
      data.form.perm_info = rows.perm_info;
      data.form.perm_list = rows.perm_list;
      data.form.perm_mod = rows.perm_mod;
      data.form.pagepermission_ids = rows1;
      data.acitveRouter = rows1;
      data.role_id = row.roles_id;
      console.log(data.form, "data.form");

        console.log(row,'pppp');
        console.log(data.acitveRouter,'pppp11');
        const dataa = {
          roles_id: row.roles_id,
          roles_name: row.roles_name,
          permission_id_list: data.acitveRouter,
        };

        console.log(dataa,'--==');
        roles_perm_del(dataa).then((res) => {
          if (res) {
            ElMessage.success("删除成功");
            fetchData();
          }
        });
      });
    };
    // 弹框确认按钮
    const submitDiaLog = () => {
      console.log(data,'..//...');
      const dataa = {
        
        roles_name: data.form.roles_name,
        roles_id: data.role_id,
        permission_id_list: data.acitveRouter,
      };
      console.log(dataa,'idididdi');
      roles_perm_p(dataa).then((res) => {
        if (res) {
          if (res.code === 200) {
            fetchData();
            ElMessage.success("编辑成功");
          } else {
            ElMessage.warning(res.msg);
          }
        }
        closeDialog();
      });
      closeDialog();
    };
    // 初始化弹框
    const closeDialog = () => {
      data.addRoleDiaLogFlag = false;
      data.form = {
        roles_name: "",
        pagepermission_ids: [],
      };
      data.acitveRouter = [];
      tree.value.setCheckedKeys([], false);
    };
  
    const tree = ref(null);
    // 选择页面时触发
    const handleNodeClick = (...obj) => {
      console.log(obj,'obj');
      // let a = obj[1].checkedNodes.filter((a) => a.node === 2);
      let a = obj[1].checkedNodes;
      console.log(a,'aaaa');
      data.acitveRouter = a.map((b) => b.id);
    };
    // 获取页面路由列表
    const getRouterList = () => {
      const dataa = {
        rows_per_page: "all",
        page: 1,
      };
      search_pagepermission(dataa).then((res) => {
        console.log(res, "页面权限列表");
        if (res) {
          const Basic = res.data;
          arr2Json(Basic);
        } else {
          data.loading = false;
        }
      });
    };
    // 转换为tree所需要的格式
    const arr2Json = (Basic) => {
      const list = Basic;
      const a = list.map((a) => a.grand_parent_path);
      console.log(a);
      const b = list.map((a) => a.perm_list);
      const modu = Array.from(new Set(a));
      const parent = Array.from(new Set(b));
      console.log(parent);
      let node1 = [];
      let c = [];

      // console.log('cc123',c);
      modu.forEach((b) => {
        console.log(b,'bbbbb');
        if (b == "user_manage") {
          c.push({
            perm_info: "用户管理",
            path: "user_manage",
            children: [],
          });
        } else if (b == "order_manage") {
          c.push({
            perm_info: "订单管理",
            path: "order_manage",
            children: [],
          });
        } else if (b == "device_manage") {
          c.push({
            perm_info: "设备管理",
            path: "device_manage",
            children: [],
          });
        } else if (b == "fee_template") {
          c.push({
            perm_info: "计费模板管理",
            path: "fee_template",
            children: [],
          });
        }  else if (b == "setting") {
          c.push({
            perm_info: "系统配置管理",
            path: "setting",
            children: [],
          });
        } else if (b == "report_manage") {
          c.push({
            perm_info: "报表管理",
            path: "report_manage",
            children: [],
          });
        }else if (b == "gis") {
          c.push({
            perm_info: "GIS管理",
            path: "gis",
            children: [],
          });
        }else if (b == "revenue_manage") {
          c.push({
            perm_info: "运营管理",
            path: "revenue_manage",
            children: [],
          });
        }else if (b == "news") {
          c.push({
            perm_info: "消息管理",
            path: "news",
            children: [],
          });
        }
        else if (b == "fault_manage") {
          c.push({
            perm_info: "运维管理",
            path: "fault_manage",
            children: [],
          });
        }
      });
      const creatNode1 = new Promise((res) => {
        parent.forEach((item, index) => {
          parent[index] = {
            perm_info: item,
            children: [],
            parent: "",
          };
        });
        node1 = parent;
        res(node1);
      });
      const creatNode2 = new Promise((res) => {
        creatNode1.then((res) => {
          res.forEach((p) => {
            list.forEach((l) => {
              if (p.perm_info == l.perm_list) {
                p.children.push(l);
                p.parent = l.grand_parent_path;
              }
            });
          });
          node1 = res;
        });
        res(node1);
      });
      creatNode2.then((res) => {
        c.forEach((c) => {
          res.forEach((r) => {
            if (c.path == r.parent) {
              c.children.push(r);
            }
          });
        });
        console.log(c,'ccccc');
        data.routerData = c;
      });
    };
    onMounted(() => {
      fetchData();
      // fetchPowerData();
      getRouterList();
    });
    return {
      ...toRefs(data),
      handleEdit,
      handleDelete,
      submitDiaLog,
      fetchData,
      closeDialog,
      // OpenrouterDiaLog,
      handleNodeClick,
      tree,
    };
  },
};
</script>
